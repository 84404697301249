<template>
  <Page class="drivers-fail-content">
    <!-- 导航栏 -->
    <NavBar title="司机认证" @clickLeft="handleRouterBack" :customClickLeft="true"> </NavBar>

    <div class="flex-1 overflow-scroll bg-white d-flex flex-column driver-wrap-contain">
      <template v-if="validityPeriodNotice.tips">
        <div class="px-24 pt-16">
          <div class="p-8 rounded-4 text-14" :class="validityPeriodNotice.cls">
            {{ validityPeriodNotice.tips }}
          </div>
        </div>
      </template>

      <div class="flex justify-between px-24 pt-32 pb-18">
        <div class="text-16">
          <span class="text-muted">司机认证状态： </span>
          <span :class="status.cls">{{ status.text }}</span>
        </div>
        <div>
          <div v-if="status.recertification" class="flex items-center">
            <Icon name="icon_wode_shoujihao-2"></Icon>
            <div class="pl-4">
              <span class="text-primary text-16" @click="onRecertification">
                {{ status.recertificationText || '重新认证' }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex items-center px-12 py-16 mx-24 mb-32 rounded-4"
        style="background: #FFE7E8;"
        v-if="userVertifiedRef.authStatus === 3"
      >
        <Icon name="icon_common_toast_warning_yellow"></Icon>
        <div class="pl-8 text-14 text-danger">
          {{ userVertifiedRef.refuseReason.map(item => item.remarks).join('、') }}
        </div>
      </div>

      <div class="flex-1 driver-wrap">
        <!-- 身份信息 -->
        <div class="px-24 content" v-if="allLoading">
          <label class="text-18 text-main font-500">身份信息</label>
          <div class="flex justify-between pt-16 id-card-wrap">
            <tos-uploader
              class="card id-card-front"
              v-model="userVertifiedRef.idCardFrontUrlLink"
              :disabled="true"
              bgType="shenfenzheng-zhu"
              >身份证人像页
            </tos-uploader>
            <tos-uploader
              class="card id-card-back"
              v-model="userVertifiedRef.idCardBackUrlLink"
              :disabled="true"
              bgType="shenfenzheng-fu"
              >身份证国徽页
            </tos-uploader>
          </div>
        </div>
        <div class="px-24 pt-24">
          <tosb-description label="手机号" :value="userVertifiedRef.driverPhone"></tosb-description>
          <tosb-description label="身份证姓名" :value="userVertifiedRef.name"></tosb-description>
          <tosb-description label="身份证号码" :value="userVertifiedRef.idCard"></tosb-description>
          <tosb-description label="身份证地址" :value="userVertifiedRef.address"></tosb-description>
          <tosb-description label="身份证有效期" :value="idCardDateStr"></tosb-description>
          <tosb-description
            label="签发机关"
            v-if="isShowReviewKey"
            :value="userVertifiedRef.idCardIssueOrganization"
          ></tosb-description>
        </div>
        <!-- 驾驶证 -->
        <div class="px-24 mt-24 content" v-if="allLoading">
          <label class="text-18 text-main font-500">驾驶证信息</label>

          <div class="flex justify-between pt-16 driver-license-wrap flex-wrap">
            <tos-uploader
              class="card drvier-license"
              v-model="userVertifiedRef.licenseUrlLink"
              :disabled="true"
              bgType="jiashizheng"
              >驾驶证主副页
            </tos-uploader>
            <tos-uploader
              class="card qualification-license"
              v-model="userVertifiedRef.qualificationLicenceMainUrlLink"
              :disabled="true"
              bgType="congyezigezheng-zhu"
              >从业资格证主副页
            </tos-uploader>
            <tos-uploader
              class="card qualification-license pt-16"
              v-model="userVertifiedRef.qualificationLicenceSideUrlLink"
              :disabled="true"
              bgType="congyezigezheng-zhu"
              >从业资格证其他页
            </tos-uploader>
          </div>
        </div>
        <div class="px-24 py-24">
          <tosb-description label="驾驶证编号" :value="userVertifiedRef.driverLicenseNumber"></tosb-description>
          <tosb-description label="准驾车型" :value="userVertifiedRef.driveType"></tosb-description>
          <tosb-description
            label="发证机关"
            :value="userVertifiedRef.driverLicenseIssueOrganization"
          ></tosb-description>
          <tosb-description label="驾驶证有效期" :value="timeStr"></tosb-description>
          <tosb-description v-if="isShowReviewKey" label="驾驶证初始领证日期" :value="driverInitStr"></tosb-description>
          <tosb-description
            v-if="isShowReviewKey"
            label="驾驶证年审有效期至"
            :value="driverValidStr"
          ></tosb-description>
          <tosb-description
            v-if="isShowReviewKey"
            label="驾驶证档案编号"
            :value="userVertifiedRef.fileNumber || '--'"
          ></tosb-description>
          <tosb-description
            v-if="isShowReviewKey"
            label="驾驶证住址"
            :value="userVertifiedRef.driverLicenseAddress || '--'"
          ></tosb-description>

          <tosb-description label="从业资格证号" :value="userVertifiedRef.qualificationNumber"></tosb-description>
          <tosb-description label="资格证有效期" :value="qualificationDateStr"></tosb-description>
          <tosb-description
            v-if="isShowReviewKey"
            label="从业资格类别"
            :value="userVertifiedRef.qualificationLicenseType || '--'"
          ></tosb-description>
          <tosb-description
            v-if="isShowReviewKey"
            label="资格证初始领证日期"
            :value="qualificationInitStr"
          ></tosb-description>
          <tosb-description
            v-if="isShowReviewKey"
            label="资格证准驾车型"
            :value="userVertifiedRef.qualificationLicenseDriveType.join(',') || '--'"
          ></tosb-description>
          <tosb-description
            v-if="isShowReviewKey"
            label="资格证住址"
            :value="userVertifiedRef.qualificationLicenseAddress || '--'"
          ></tosb-description>
          <tosb-description
            v-if="isShowReviewKey"
            label="资格证发证机关"
            :value="userVertifiedRef.qualificationLicenseIssueOrganization || '--'"
          ></tosb-description>
          <tosb-description
            v-if="isShowReviewKey"
            label="继续教育等级"
            :value="userVertifiedRef.continuingEducationLevel || '--'"
          ></tosb-description>
          <tosb-description v-if="isShowReviewKey" label="继续教育有效期" :value="educationValidStr"></tosb-description>
          <tosb-description
            v-if="isShowReviewKey"
            label="诚信考核等级"
            :value="userVertifiedRef.qualificationLicenseIntegrityAssessmentRecord || '--'"
          ></tosb-description>
          <tosb-description
            v-if="isShowReviewKey"
            label="诚信考核有效期"
            :value="qualificationValidStr"
          ></tosb-description>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import NavBar from '@/components/NavBar/index.vue';
import { defineComponent, ref, onBeforeMount, computed, nextTick } from '@vue/composition-api';
import { Button, Uploader, Popover, Toast } from 'vant';
import { formatToDateTime } from '@wlhy-web-lib/shared';
import { useRouter } from '@/utils/compsitionHack';
import { PageEnum } from '@/enums/pageEnum';
import { useDriverVerifiedStore } from '@/store/driverVerfied';
import useFileNameToUrl from '@hook/useFileNameToUrl';
import { DriverAtuhStatus } from '../constants';
import TosUploader from '@/components/uploader/uploader.vue';
import TosbDescription from '@/components/Description/index.vue';
import { useUserStore } from '@/store/user';
import AuthModeFactory from '@/services/AuthenticationMode/AuthModeFactory';
import Driver from '@/services/AuthenticationMode/Driver';
import ModeEnum from '@/services/AuthenticationMode/ModeEnum';
import { getExpiredDateMap } from '@/utils/timeDiff';
import dayjs from 'dayjs';

const authModeFactory = new AuthModeFactory(new Driver());

export default defineComponent({
  name: 'customer',
  components: {
    Button,
    TosUploader,
    TosbDescription,
    Uploader,
    Toast,
    Popover,
    NavBar
  },
  setup(_, ctx) {
    const allLoading = ref(false);
    const userVerifiedStore = useDriverVerifiedStore();

    // 司机身份 ，认证通过的时候才能继续编辑信息
    const userStore = useUserStore();
    const isDriver = computed(() => userStore.isDriver);
    const isShowReviewKey = computed(() => {
      return userStore.showReviewKeys;
    });
    // 详情界面的编辑全是完整模式
    userVerifiedStore.updateAuthModeFields(authModeFactory.build(ModeEnum.INTEGRITY));

    const userVertifiedRef = computed(() => userVerifiedStore.verifiedInfo);
    const timeStr = computed(() => {
      return `${formatToDateTime(userVerifiedStore.verifiedInfo.driverLicenseStartDate, 'yyyy/MM/dd')} -
       ${formatToDateTime(userVerifiedStore.verifiedInfo.driverLicenseEndDate, 'yyyy/MM/dd')}`;
    });

    const qualificationDateStr = computed(() => {
      const { qualificationLicenceEndDate, qualificationLicenceStartDate } = userVerifiedStore.verifiedInfo;
      if (typeof qualificationLicenceEndDate == 'number' && typeof qualificationLicenceStartDate === 'number') {
        return `${formatToDateTime(qualificationLicenceStartDate, 'yyyy/MM/dd')} -
       ${formatToDateTime(qualificationLicenceEndDate, 'yyyy/MM/dd')}`;
      }
      return '--';
    });
    const idCardDateStr = computed(() => {
      const { idCardStartDate, idCardEndDate } = userVerifiedStore.verifiedInfo;
      if (typeof idCardEndDate == 'number' && typeof idCardStartDate === 'number') {
        return `${formatToDateTime(idCardStartDate, 'yyyy/MM/dd')} -
       ${formatToDateTime(idCardEndDate, 'yyyy/MM/dd')}`;
      }
      return '--';
    });
    const qualificationValidStr = computed(() => {
      const {
        qualificationLicenseIntegrityAssessmentValidFrom,
        qualificationLicenseIntegrityAssessmentValidTo
      } = userVerifiedStore.verifiedInfo;
      if (
        typeof qualificationLicenseIntegrityAssessmentValidTo == 'number' &&
        typeof qualificationLicenseIntegrityAssessmentValidFrom === 'number'
      ) {
        return `${formatToDateTime(qualificationLicenseIntegrityAssessmentValidFrom, 'yyyy/MM/dd')} -
       ${formatToDateTime(qualificationLicenseIntegrityAssessmentValidTo, 'yyyy/MM/dd')}`;
      }
      return '--';
    });
    const driverValidStr = computed(() => {
      const { driverLicenseAnnualReviewDate } = userVerifiedStore.verifiedInfo;
      const mDate = dayjs(driverLicenseAnnualReviewDate);
      if (driverLicenseAnnualReviewDate && mDate.isValid()) {
        return `${formatToDateTime(mDate.valueOf(), 'yyyy/MM/dd')}`;
      }
      return '--';
    });

    const driverInitStr = computed(() => {
      const { driverLicenseInitialIssueDate } = userVerifiedStore.verifiedInfo;
      const mDate = dayjs(driverLicenseInitialIssueDate);
      if (driverLicenseInitialIssueDate && mDate.isValid()) {
        return `${formatToDateTime(mDate.valueOf(), 'yyyy/MM/dd')}`;
      }
      return '--';
    });

    const qualificationInitStr = computed(() => {
      const { qualificationLicenseInitialIssueDate } = userVerifiedStore.verifiedInfo;
      const mDate = dayjs(qualificationLicenseInitialIssueDate);
      if (qualificationLicenseInitialIssueDate && mDate.isValid()) {
        return `${formatToDateTime(mDate.valueOf(), 'yyyy/MM/dd')}`;
      }
      return '--';
    });

    const educationValidStr = computed(() => {
      const { continuingEducationStartDate, continuingEducationEndDate } = userVerifiedStore.verifiedInfo;
      if (typeof continuingEducationStartDate == 'number' && typeof continuingEducationEndDate === 'number') {
        return `${formatToDateTime(continuingEducationStartDate, 'yyyy/MM/dd')} -
       ${formatToDateTime(continuingEducationEndDate, 'yyyy/MM/dd')}`;
      }
      return '--';
    });

    const status = computed(() => {
      const authStatus = userVerifiedStore.verifiedInfo.authStatus;
      const options = {
        [DriverAtuhStatus.Waiting]: {
          text: '已提交',
          cls: 'text-main pl-4',
          recertification: true
        },
        [DriverAtuhStatus.Finish]: {
          text: '认证通过',
          cls: 'text-success  pl-4',
          recertification: isDriver ? true : false, // 司机身份修改
          recertificationText: '修改'
        },
        [DriverAtuhStatus.Fail]: {
          text: '认证失败',
          cls: 'text-danger  pl-4',
          recertification: true
        }
      };
      return options[authStatus] || {};
    });

    onBeforeMount(async () => {
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: '加载中...'
      });
      await userVerifiedStore.queryVerifiedInfo(() => {
        setTimeout(() => {
          Toast.clear();
        }, 500);
        let imgListKey = [
          'idCardFrontUrl',
          'idCardBackUrl',
          'licenseUrl',
          'qualificationLicenceMainUrl',
          'qualificationLicenceSideUrl'
        ];
        Promise.all(
          [
            'idCardFrontUrl',
            'idCardBackUrl',
            'licenseUrl',
            'qualificationLicenceMainUrl',
            'qualificationLicenceSideUrl'
          ].map(item => useFileNameToUrl(userVertifiedRef.value[item]))
        ).then(result => {
          imgListKey.forEach((item, index) => {
            userVerifiedStore.updateInfo({
              [`${item}Link`]: [{ url: result[index] }]
            });
          });
          nextTick(() => {
            allLoading.value = true;
          });
        });
      });
    });

    const router = useRouter();

    const onRecertification = async () => {
      userVerifiedStore.updateIsAmendDriverAuthInfo(
        userVerifiedStore.verifiedInfo.authStatus === DriverAtuhStatus.Finish
      );
      userVerifiedStore.isReAuth = false;
      userVerifiedStore.showComplete = true;
      router.push({
        path: PageEnum.BASE_DRIVER_AUTH,
        query: {
          // 编辑状态
          isEdit: true,
          driverId: userVerifiedStore.verifiedInfo.driverId
        }
      });
      return;
    };
    // 提交表单数据
    const handleRouterBack = () => {
      if (ctx.root.$route.query.needBack == 1) {
        router.go(-1);
      } else {
        router.push('/mine');
      }
    };

    function onPreview(index) {
      console.log(index);
      // debugger;
    }

    const validityPeriodNotice = computed(() => {
      const { idCardEndDate, driverLicenseEndDate, qualificationLicenceEndDate } = userVertifiedRef.value;
      const { expired, nearExpired } = getExpiredDateMap([
        {
          value: idCardEndDate,
          label: '身份证',
          tips: 30,
          unit: 'day'
        },
        {
          value: driverLicenseEndDate,
          label: '驾驶证',
          tips: 30,
          unit: 'day'
        },
        {
          value: qualificationLicenceEndDate,
          label: '从业资格证',
          tips: 30,
          unit: 'day'
        }
      ]);
      let cls = '';
      let tips = '';
      if (expired.length > 0) {
        cls = 'tips-danger';
      } else if (nearExpired.length > 0) {
        cls = 'tips-warning';
      }
      const expiredLabels = expired.map(it => `【${it}】`).join('');
      const nearExpiredLabel = nearExpired.map(it => `【${it}】`).join('');
      if (expiredLabels) {
        tips += `${expiredLabels}已到期，`;
      }
      if (nearExpiredLabel) {
        tips += `${nearExpiredLabel}即将到期，`;
      }
      if (tips) {
        tips += '为保证接单不受影响，请尽快更新！';
      }
      return {
        cls,
        tips
      };
    });

    return {
      userVertifiedRef,
      showPopover: false,
      allLoading,
      handleRouterBack,
      formatToDateTime,
      status,
      onRecertification,
      onPreview,
      timeStr,
      handleRouteTo: path => {
        router.push(path);
      },

      validityPeriodNotice,
      qualificationDateStr,
      idCardDateStr,
      qualificationValidStr,
      driverValidStr,
      driverInitStr,
      qualificationInitStr,
      educationValidStr,
      isShowReviewKey
    };
  }
});
</script>
<style lang="less" scoped>
@import url('../styles/fail.less');
</style>
<style lang="less">
.drivers-fail-content {
  .id-card-wrap {
    .card {
      width: 1.6rem;
      height: 1.25rem;
    }
  }

  .driver-license-wrap {
    .card {
      width: 1.6rem;
      height: 1.25rem;
    }
  }

  .error-wrap {
    background: rgba(224, 44, 50, 0.1);
  }
  .tips-danger {
    background: #faebeb;
    color: #e72832;
  }
  .tips-warning {
    color: #ff7900;
    background: #fdf4eb;
  }
}
</style>
