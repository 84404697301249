import dayjs from 'dayjs';
import { isNil } from 'lodash-es';

/**
 *
 * @param {*} date 需要比较的日期
 * @param {*} target 比较日期
 * @param {*} tipDiff 快到期提醒
 * @param {*} unit 比较维度，默认是日
 */

export const isValidDate = date => {
  return dayjs(date).isValid();
};

export const timeDiff = (date, toCompaired, unit = 'day') => {
  let diff = 0;

  if (!isNil(date) && !isNil(toCompaired) && isValidDate(date) && isValidDate(toCompaired)) {
    diff = dayjs(date).diff(dayjs(toCompaired).valueOf(), unit);
  }
  return diff;
};

export const getDayDiffFromNow = date => {
  const dateFormat = dayjs(date)
    .format('YYYY-MM-DD')
    .valueOf();
  const now = dayjs()
    .format('YYYY-MM-DD')
    .valueOf();
  return timeDiff(date, now, 'day');
};

export const getMonthDiffFromNow = date => {
  const dateFormat = dayjs(date)
    .format('YYYY-MM')
    .valueOf();
  const now = dayjs()
    .format('YYYY-MM')
    .valueOf();
  return timeDiff(dateFormat, now, 'month');
};
/**
 *
 * @param {*} arr  {value:日期,label:日期字段名,unit:day|month|year等默认day,tips:快过期日期提示,默认0}[]
 */
export const getExpiredDateMap = arr => {
  const expired = [];
  const nearExpired = [];
  arr.forEach(item => {
    if (!item) return;
    const { value, label, unit = 'day', tips = 0 } = item;
    if (!isNil(value) && isValidDate(value)) {
      const diff = unit === 'month' ? getMonthDiffFromNow(value) : getDayDiffFromNow(value);
      if (diff < 0) {
        expired.push(label);
      } else if (diff === 0 && tips <= 0) {
        nearExpired.push(label);
      } else if (diff < tips) {
        nearExpired.push(label);
      }
    }
  });
  return { expired, nearExpired };
};
