<template>
  <div class="tosb-description flex justify-between py-10">
    <div class="text-muted text-16 tosb-description-label">{{ label }}</div>
    <div class="flex-1 text-main text-16 text-right">{{ value }}</div>
  </div>
</template>

<script>
export default {
  name: 'TosbDescription',
  props: {
    label: {
      type: String,
      default: '标题'
    },
    value: {
      type: String,
      default: '内容'
    }
  }
};
</script>

<style lang="less">
.tosb-description {
  &-label {
    flex-shrink: 0;
    margin-right: 4px;
    min-width: 1rem;
  }
}
</style>
